@import url(https://fonts.googleapis.com/css2?family=Cabin&family=Dosis&family=Poppins:wght@700&family=Roboto:wght@400;500&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Libre Baskerville",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


@font-face {
  font-family: 'bassy';
  src: url('/fonts/Bassy.ttf') format('truetype'); /* Update the path and format accordingly */
  font-weight: normal;
  font-style: normal;
}
.main-nav-class{
    /* background-color:#EC8444;#1E3760 */
    background-color:#FFFFFF;
    display: flex;
    justify-content: center !important;
    flex-direction: row;
    align-items: center !important;
    /* padding: 1.5em 0 !important; */
}

.navbar-div-logo{
}
.navbar-div-text{
    color: #251d25;
}

.nav-bar-div-text-h{
    color: #251d25;
    font-size: x-large !important;
}

.mrinh6{
    margin:0 !important;
}


/* @media only screen and (max-width: 600px) {
   .navbar-div {
       flex-direction: column;
   }
   .navbar-div-logo {
       flex-basis: 100%;
       width: 100%;
   }

   .nav-bar-div-text-h{
    font-size: 33px !important;
}

.navbar-div-text1{
    flex-basis: 100%;
    justify-content: center;
    align-items: center;
}
}
    
.navbar-div-text-right{
   flex-basis: 60%;
   display: flex;
   justify-content: flex-end;
   padding-right: 10vw;
}
.list-styled{
    text-align: center;
    list-style-type: square;
}
.navbar-corner{
    
    width: 70%;
    display: flex;
    justify-content: center;
}

@media only screen and (max-width: 600px){
    .navbar-div-text-right{
        flex-basis: 100%;
        padding-right: 0;
        
    }
}
.logo-img{
    width: 80px
    
}
.navitems{
    font-weight: 300
}

.div-details-navbar{
    font-size: 0.7em;
}

.online-admission{
    background-color: white;
    padding: 10px;
    border: none;
    box-shadow: 1px 2px 3px rgba(0,0,0,0.1)
}


/* Small nav Div 


.navbar-div-small{
    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    height: 5vh;
    padding-left: 10vw;
    padding-right: 10vw;
}

.caret-right{
    outline: none;
}

.caret-right::after{
    content: "";
    float: right;
    margin-top: .5em;
}

.right-drop{
    transform: translate3d(110px, -8px, 0px) !important;
}


/* Responsive 

@media only screen and (max-width: 600px){
    .navbar-div{
        
        padding-left: 0vw;
        padding-right: 0vw;
    }

    .logo-img{
        width: 60px
    }

    .navbar-div-text h5{
        font-size: 0.8em
    }

    .navbar-div-text h6{
        font-size: 0.5em
    }

    .navbar-div-text {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

 */

.card-date-news{
    background-color: #1f1f1f;
    color: white !important;
    height: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.header {
    width: 100%;
    height: 40px;
    z-index: 1001;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    border: dashed 1px #FCCA9C;
    color: #ABB0B7;
    background-color: white;
    height: 40px;
    width: 100%;
  }
  
  .header__left {
    flex-basis: 40%;
    display: flex;
    align-items: center;
    
    justify-content: center;
    font-weight: 700;
    color: #404040;
    height: 40px;
    background-color: #FCCA9C;
    width: 100%;
    
  }

  .mobile-none{
    display: block;
  }

  .header__right {
    display: flex;
    align-items: center;
    flex-basis: 60%;
    padding: 0px;
    padding-left: 5px;
  }
  
  .header__title {
    font-size: 2em;
    margin: 0;
  }
  
  .header__email,
  .header__phone {
    display: flex;
    align-items: center;
    color: #ABB0B7;
    text-decoration: none;
    font-size: 1.1em;
    margin-right: 20px;
  }
  
  .header__icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
   
  }
  
  .header__social-link {
    display: flex;
    align-items: center;
    margin-left: 20px;
    color: #ABB0B7;
    font-size: 1.1em;
  }

  .header__button {
    background-color: blue;
    color: white;
    border-radius: 5px; /* add a little bit of border radius */
    font-size: 1em;
    border: none;
    margin-right: 4px;
  }
  


  @media only screen and (max-width: 768px) {
    .header__left {
      font-size: 11px;
      padding-right: 4px;
      padding-left: 4px;
      
    }
    .mobile-none{
      display: none;
    }
  }
  
  
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.blink_me {
  -webkit-animation: blinker 1s linear infinite;
          animation: blinker 1s linear infinite;
}

@-webkit-keyframes blinker {
  from {
    color: green;
  }

  to {
    color: red;
  }

  50% {
    opacity: 0;
  }
}

@keyframes blinker {
  from {
    color: green;
  }

  to {
    color: red;
  }

  50% {
    opacity: 0;
  }
}


a:hover {
  text-decoration: none !important;
}

.navStyle-new {
  width: 100%;
}


.faculty-image {
  width: 100%;
  height: 30vh;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.3);
}

@media only screen and (min-width: 600px) {
  .navStyle-new {
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: flex-end;
    width: 100%;
    background-color: white !important;
    background-color: #b94735 !important;
  }

  .navStyle-new-1 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #001529 !important;

  }

  .m-display {
    display: none !important;
  }

  .m-none {
    display: unset !important;
  }

}




.departmental-header-text {
  font-weight: 500 !important;
}


@media only screen and (max-width: 600px) {
  .faculty-image {
    width: 80%;
    height: 80%;
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.3);
    margin-bottom: 20px;
  }

  .departmental-header-text {
    font-size: 14px !important;
  }

  /* .m-display{
    display: unset !important;
  }
  */

  .m-none {
    display: none !important;
  }

}



.new-principal-div {
  border: solid 3px grey;
  padding: 20px;
  background-color: #fff6ca;
}

.bg-primary {
  background-color: #001529 !important;
}

.ant-menu-vertical>.ant-menu-item,
.ant-menu-vertical-left>.ant-menu-item,
.ant-menu-vertical-right>.ant-menu-item,
.ant-menu-inline>.ant-menu-item,
.ant-menu-vertical>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-vertical-left>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-vertical-right>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title {
  height: 40px;
  line-height: 40px;
  font-weight: 700;
}

/* new class created by mrin*/

.hr {
  height: 2px;
  background: white;
  width: 900px;
}

.round-date {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  font-weight: 600;
  color: white;
  border-radius: 50%;
  height: 100px;
  width: 100px;
  border: 3px solid white;
}
/* 
font-family: 'Cabin', sans-serif;
font-family: 'Dosis', sans-serif;
font-family: 'Poppins', sans-serif;
font-family: 'Roboto', sans-serif; */

.fontFix {
  /* font-family:  'Poppins', sans-serif; */
}

.round {
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #EC8444;
  color: #251D25;
  border-radius: 50%;
  padding: 1em;
  margin: 3em;
  transition-duration: 5sec;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition-delay: 100ms;
  -o-transition-timing-function: ease-in-out;
  cursor: pointer;
}

.round:hover {
  background-color: #EBDB86;
  transform: scale(0.9);
}



.news {
  background: #EBDB86;
}

.news-title {
  color: #B94735;
  cursor: pointer;
  font-weight: 400 !important;
}

.news-title:hover {
  color: #251D25
}

.vdamaz {
  transform: scale(0.95);
  -webkit-animation: ease-in-out 2s;
          animation: ease-in-out 2s;
}

.vdamaz:hover {
  transform: scale(1);
}

.navItemPandu {
  color: white !important;
  border-bottom: none !important;
  padding: 0 0px !important;
  font-weight: 600;
  font-size: 12px;
}

.navItemPandu:hover {
  background: #251D25 !important;
}

.footer {
  background: #251D25 !important;
  color: #EBDB86;
  width: 100%;
}

.foot1h {
  color: #EBDB86;
}

.add p {
  font-size: medium;
  margin: 0;
  padding: 0;
}

.MuiListItem-button:hover {
  background-color: #EBdB86 !important;
}

.logo-img {
  width: 120px;
  text-align: center;
}

@media only screen and (max-width: 1024px) {

  .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item,
  .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu {
    margin: 0 10px !important;
  }
}


@media only screen and (max-width: 768px) {

  .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item,
  .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu {
    margin: 0 10px !important;
  }

  .nav-bar-div-text-h {
    color: #251d25 !important;
    font-size: x-large !important;
    text-align: center;
  }

  .ant-menu-submenu-popup>.ant-menu {
    background-color: #b94735 !important;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #b94735 !important;
  }
}

@media only screen and (max-width: 768px) {
  .row {
    display: flex !important;
    flex-wrap: wrap !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}

.feestable {
  margin: 1em 0;
}

.feestable td {
  font-weight: 400;
  padding: 5px 30px;
}

.noticecard {
  background: transparent;
}

.subhead {
  border-bottom: 3px solid black;
}

.subhead:hover {
  border-bottom: 2px solid black;
}

@media (min-width: 1400px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 80% !important;
  }

  .gapround {
    padding: 0 50px;
  }
}

p {
  line-height: 1.875em !important;
}



.table>thead>tr>td {
  background-color: #3b87eb;
  color: white;
  /* border-color: white; */
  font-weight: bold;
  padding: 3px;
  text-align: center;
  font-size: 14px;
}

.table>tbody>tr>td {

  padding: 3px;
  font-size: 12px;
  font-weight: 500;
}

p {
  margin: 0 !important;
}

.table-new-color>thead>tr>td {
  background-color: #c3592f;
}



.top-nav-top{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #E46D25;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
}


.header-style-h1{
  color: #E36D25 !important;
  font-size: 40px;
  margin-bottom: 15px;
  font-family: 'bassy';
}

.top-nav-top-email{
  flex-basis: 30%;
}

.top-nav-top-menu{
  flex-basis: 40%;
  display: flex;
  justify-content: flex-end;
}

.top-nav-top-menu-item{
  font-weight: 700;
  color: white !important;
}

.top-nav-top-social{
  flex-basis: 5%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.top-nav-top-search{
  flex-basis: 15%;
}

.pandu-text-class{
  width: 270px;
}

.blink_me {
  font-size: 16px;
  -webkit-animation: color-change 1s infinite;
          animation: color-change 1s infinite;
}

.header-image-div{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.header-image-div-item{
  width: 120px;
  margin-right: 10px;
}


.main-nav-class{
  width: 100%;

}

.main-nav-class-div-1{
  flex-basis: 10%;
}

.navbar-div-tex{
  flex-basis: 40% !important;
  text-align: center;
}

.main-nav-class-div-2{
  flex-basis: 20%;
  text-align: center;
  font-weight: 600;
}

.nav-bar-div-text-h{
  font-size: 30px !important;
  font-weight: bold !important;
  text-align: center;
}


@-webkit-keyframes color-change {
  0% {
    color: #2D58FF;
  }

  33% {
    color: #FBAF1B;
  }

  66% {
    color: #0DA79D;
  }

  100% {
    color: #2D58FF;
  }
}


@keyframes color-change {
  0% {
    color: #2D58FF;
  }

  33% {
    color: #FBAF1B;
  }

  66% {
    color: #0DA79D;
  }

  100% {
    color: #2D58FF;
  }
}
.actual-padding{
    padding-top: 3vw;
    padding-bottom: 4vw;
}
.list-styled{
    text-align: left
}

.title{
    color: white
}
