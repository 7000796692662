.main-nav-class{
    /* background-color:#EC8444;#1E3760 */
    background-color:#FFFFFF;
    display: flex;
    justify-content: center !important;
    flex-direction: row;
    align-items: center !important;
    /* padding: 1.5em 0 !important; */
}

.navbar-div-logo{
}
.navbar-div-text{
    color: #251d25;
}

.nav-bar-div-text-h{
    color: #251d25;
    font-size: x-large !important;
}

.mrinh6{
    margin:0 !important;
}


/* @media only screen and (max-width: 600px) {
   .navbar-div {
       flex-direction: column;
   }
   .navbar-div-logo {
       flex-basis: 100%;
       width: 100%;
   }

   .nav-bar-div-text-h{
    font-size: 33px !important;
}

.navbar-div-text1{
    flex-basis: 100%;
    justify-content: center;
    align-items: center;
}
}
    
.navbar-div-text-right{
   flex-basis: 60%;
   display: flex;
   justify-content: flex-end;
   padding-right: 10vw;
}
.list-styled{
    text-align: center;
    list-style-type: square;
}
.navbar-corner{
    
    width: 70%;
    display: flex;
    justify-content: center;
}

@media only screen and (max-width: 600px){
    .navbar-div-text-right{
        flex-basis: 100%;
        padding-right: 0;
        
    }
}
.logo-img{
    width: 80px
    
}
.navitems{
    font-weight: 300
}

.div-details-navbar{
    font-size: 0.7em;
}

.online-admission{
    background-color: white;
    padding: 10px;
    border: none;
    box-shadow: 1px 2px 3px rgba(0,0,0,0.1)
}


/* Small nav Div 


.navbar-div-small{
    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    height: 5vh;
    padding-left: 10vw;
    padding-right: 10vw;
}

.caret-right{
    outline: none;
}

.caret-right::after{
    content: "";
    float: right;
    margin-top: .5em;
}

.right-drop{
    transform: translate3d(110px, -8px, 0px) !important;
}


/* Responsive 

@media only screen and (max-width: 600px){
    .navbar-div{
        
        padding-left: 0vw;
        padding-right: 0vw;
    }

    .logo-img{
        width: 60px
    }

    .navbar-div-text h5{
        font-size: 0.8em
    }

    .navbar-div-text h6{
        font-size: 0.5em
    }

    .navbar-div-text {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

 */
