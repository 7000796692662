.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  from {
    color: green;
  }

  to {
    color: red;
  }

  50% {
    opacity: 0;
  }
}


a:hover {
  text-decoration: none !important;
}

.navStyle-new {
  width: 100%;
}


.faculty-image {
  width: 100%;
  height: 30vh;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.3);
}

@media only screen and (min-width: 600px) {
  .navStyle-new {
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: flex-end;
    width: 100%;
    background-color: white !important;
    background-color: #b94735 !important;
  }

  .navStyle-new-1 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #001529 !important;

  }

  .m-display {
    display: none !important;
  }

  .m-none {
    display: unset !important;
  }

}




.departmental-header-text {
  font-weight: 500 !important;
}


@media only screen and (max-width: 600px) {
  .faculty-image {
    width: 80%;
    height: 80%;
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.3);
    margin-bottom: 20px;
  }

  .departmental-header-text {
    font-size: 14px !important;
  }

  /* .m-display{
    display: unset !important;
  }
  */

  .m-none {
    display: none !important;
  }

}



.new-principal-div {
  border: solid 3px grey;
  padding: 20px;
  background-color: #fff6ca;
}

.bg-primary {
  background-color: #001529 !important;
}

.ant-menu-vertical>.ant-menu-item,
.ant-menu-vertical-left>.ant-menu-item,
.ant-menu-vertical-right>.ant-menu-item,
.ant-menu-inline>.ant-menu-item,
.ant-menu-vertical>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-vertical-left>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-vertical-right>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title {
  height: 40px;
  line-height: 40px;
  font-weight: 700;
}

/* new class created by mrin*/

.hr {
  height: 2px;
  background: white;
  width: 900px;
}

.round-date {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  font-weight: 600;
  color: white;
  border-radius: 50%;
  height: 100px;
  width: 100px;
  border: 3px solid white;
}



@import url('https://fonts.googleapis.com/css2?family=Cabin&family=Dosis&family=Poppins:wght@700&family=Roboto:wght@400;500&display=swap');
/* 
font-family: 'Cabin', sans-serif;
font-family: 'Dosis', sans-serif;
font-family: 'Poppins', sans-serif;
font-family: 'Roboto', sans-serif; */

.fontFix {
  /* font-family:  'Poppins', sans-serif; */
}

.round {
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #EC8444;
  color: #251D25;
  border-radius: 50%;
  padding: 1em;
  margin: 3em;
  transition-duration: 5sec;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition-delay: 100ms;
  -o-transition-timing-function: ease-in-out;
  cursor: pointer;
}

.round:hover {
  background-color: #EBDB86;
  transform: scale(0.9);
}



.news {
  background: #EBDB86;
}

.news-title {
  color: #B94735;
  cursor: pointer;
  font-weight: 400 !important;
}

.news-title:hover {
  color: #251D25
}

.vdamaz {
  transform: scale(0.95);
  animation: ease-in-out 2s;
}

.vdamaz:hover {
  transform: scale(1);
}

.navItemPandu {
  color: white !important;
  border-bottom: none !important;
  padding: 0 0px !important;
  font-weight: 600;
  font-size: 12px;
}

.navItemPandu:hover {
  background: #251D25 !important;
}

.footer {
  background: #251D25 !important;
  color: #EBDB86;
  width: 100%;
}

.foot1h {
  color: #EBDB86;
}

.add p {
  font-size: medium;
  margin: 0;
  padding: 0;
}

.MuiListItem-button:hover {
  background-color: #EBdB86 !important;
}

.logo-img {
  width: 120px;
  text-align: center;
}

@media only screen and (max-width: 1024px) {

  .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item,
  .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu {
    margin: 0 10px !important;
  }
}


@media only screen and (max-width: 768px) {

  .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item,
  .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu {
    margin: 0 10px !important;
  }

  .nav-bar-div-text-h {
    color: #251d25 !important;
    font-size: x-large !important;
    text-align: center;
  }

  .ant-menu-submenu-popup>.ant-menu {
    background-color: #b94735 !important;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #b94735 !important;
  }
}

@media only screen and (max-width: 768px) {
  .row {
    display: flex !important;
    flex-wrap: wrap !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}

.feestable {
  margin: 1em 0;
}

.feestable td {
  font-weight: 400;
  padding: 5px 30px;
}

.noticecard {
  background: transparent;
}

.subhead {
  border-bottom: 3px solid black;
}

.subhead:hover {
  border-bottom: 2px solid black;
}

@media (min-width: 1400px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 80% !important;
  }

  .gapround {
    padding: 0 50px;
  }
}

p {
  line-height: 1.875em !important;
}



.table>thead>tr>td {
  background-color: #3b87eb;
  color: white;
  /* border-color: white; */
  font-weight: bold;
  padding: 3px;
  text-align: center;
  font-size: 14px;
}

.table>tbody>tr>td {

  padding: 3px;
  font-size: 12px;
  font-weight: 500;
}

p {
  margin: 0 !important;
}

.table-new-color>thead>tr>td {
  background-color: #c3592f;
}



.top-nav-top{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #E46D25;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
}


.header-style-h1{
  color: #E36D25 !important;
  font-size: 40px;
  margin-bottom: 15px;
  font-family: 'bassy';
}

.top-nav-top-email{
  flex-basis: 30%;
}

.top-nav-top-menu{
  flex-basis: 40%;
  display: flex;
  justify-content: flex-end;
}

.top-nav-top-menu-item{
  font-weight: 700;
  color: white !important;
}

.top-nav-top-social{
  flex-basis: 5%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.top-nav-top-search{
  flex-basis: 15%;
}

.pandu-text-class{
  width: 270px;
}

.blink_me {
  font-size: 16px;
  animation: color-change 1s infinite;
}

.header-image-div{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.header-image-div-item{
  width: 120px;
  margin-right: 10px;
}


.main-nav-class{
  width: 100%;

}

.main-nav-class-div-1{
  flex-basis: 10%;
}

.navbar-div-tex{
  flex-basis: 40% !important;
  text-align: center;
}

.main-nav-class-div-2{
  flex-basis: 20%;
  text-align: center;
  font-weight: 600;
}

.nav-bar-div-text-h{
  font-size: 30px !important;
  font-weight: bold !important;
  text-align: center;
}


@keyframes color-change {
  0% {
    color: #2D58FF;
  }

  33% {
    color: #FBAF1B;
  }

  66% {
    color: #0DA79D;
  }

  100% {
    color: #2D58FF;
  }
}